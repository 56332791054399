/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components), {Text} = _components;
  if (!Text) _missingMdxReference("Text", true);
  return React.createElement(React.Fragment, null, React.createElement(Text, {
    sx: {
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }
  }, React.createElement(_components.p, null, "Welcome")), "\n", React.createElement(_components.p, null, "I'm Linda, software developer and technical writer.\nI've been creating custom REST API documentation since 2018.\nI offer ", React.createElement(_components.a, {
    href: "/full-service-docs"
  }, "full-service API documentation"), " so that you can get your API docs written, published and maintainable—ready to grow with you."), "\n", React.createElement(_components.p, null, "Check out my ", React.createElement(_components.a, {
    href: "/portfolio"
  }, "portfolio"), ",\nor sign up for a ", React.createElement(_components.a, {
    href: "https://calendly.com/linda-b-robledo"
  }, "free consultation"), " today."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
